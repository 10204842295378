<template>
  <div class="faq-section-n">
    <h3>أسئلة شائعة</h3>
    <h2>وإجابات لكل استفساراتكم</h2>
    <div class="faq-items-n">
      <div class="faq-items-sub">
      <div class="faq-item-min" v-for="(item, index) in faqItems_1" :key="index">
        <button @click="toggleAnswer(index)" :class="{ active: activeIndex === index }">
          {{ item.question }}
          <span :class="{ activeSpan: activeIndex === index }">
            <span v-if="activeIndex === index">-</span>
            <span v-else>+</span>
          </span>
        </button>
        <transition name="fade">
          <div v-show="activeIndex === index" :class="['answer', { 'answer-active': activeIndex === index }]">
            {{ item.answer }}
          </div>
        </transition>
      </div>
      </div>
      <div class="faq-items-sub">
      <div class="faq-item-min" v-for="(item, index) in faqItems_2" :key="index + 6">
        <button @click="toggleAnswer(index + 6)" :class="{ active: activeIndex === index + 6 }">
          {{ item.question }}
          <span :class="{ activeSpan: activeIndex === index + 6 }">
            <span v-if="activeIndex === index + 6">-</span>
            <span v-else>+</span>
          </span>
        </button>
        <transition name="fade">
          <div v-show="activeIndex === index + 6" :class="['answer', { 'answer-active': activeIndex === index + 6 }]">
            {{ item.answer }}
          </div>
        </transition>
      </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ViewThree',
  data() {
    return {
      activeIndex: null,
      faqItems_1: [
        {
          question: 'لماذا يجب عليّ اختيار أكاديمية مونجلش الدولية؟',
          answer: 'أكاديمية مونجلش الدولية تتميز بتقديم منهج "المعايشة" الحصري والمسجل بمكتبة الكونجرس الأمريكية. المنهج يُدرس بشكل كامل عبر الإنترنت ويشمل 11 ناديًا تفاعليًا مليئًا بالتطبيقات العملية، مما يساعد المتعلمين على ممارسة اللغة بشكل ممتع وفعّال.'
        },
         {
          question: 'ما هي نوادي منهج المعايشة؟',
          answer: 'نوادي المعايشة في مونجلش مقسمة إلى أندية أساسية، تفاعلية، وتربوية. الأندية الأساسية تشمل: نادي المحادثة، نادي المناظرات، نادي السينما، ونادي القراءة. أما الأندية التفاعلية فهي: نادي الصوتيات، نادي الكتابة، نادي الرسم، ونادي المواهب. بالإضافة إلى أندية تربوية مخصصة مثل: ماما مونجي والنادي الاجتماعي.'
        },
       {
          question: 'كيف يمكنني متابعة تقدم مستواي؟',
          answer: 'نحن نوفر تقارير شهرية مفصلة عن مستوى الطالب في اللغة، بما في ذلك تقييم الأداء في مختلف المهارات اللغوية. يمكن للطلاب التواصل مع معلميهم بشكل مستمر لتلقي ملاحظات فردية حول تقدمهم.'
        },
         {
          question: 'ما هي مدة الدورة والوقت المطلوب لتعلم اللغة الإنجليزية بطلاقة؟',
          answer: 'يتطلب الطلاب من 6 إلى 12 شهرًا لتعلم اللغة الإنجليزية بطلاقة بناءً على عدد الساعات الدراسية الأسبوعية ومستوى التفاعل.'
        },
        {
          question: 'هل الحصص مسجلة أم مباشرة؟',
          answer: 'كل الحصص تُقدم بشكل مباشر (لايف) عبر منصة زووم. وفي حال الغياب لأسباب قهرية، يمكن تسجيل الحصة بإبلاغ مسبق للإدارة.'
        },
         {
          question: 'ما هي وسائل الدفع المتاحة؟',
          answer: 'يمكن الدفع حاليًا عبر الإنترنت فقط باستخدام بطاقة الائتمان من خلال موقعنا الإلكتروني أو عبر روابط دفع stripe.'
        },
      ],
      faqItems_2: [
        {
          question: 'ما هي المؤهلات التي يتمتع بها معلمو مونجلش؟',
          answer: 'فريق التدريس في مونجلش يتكون من نخبة من المعلمين ذوي الجنسيات المختلفة، مثل المصرية، الأمريكية، البريطانية، الكندية، والأسترالية. جميع المعلمين معتمدون دوليًا ولديهم خبرة عميقة في تدريس اللغة الإنجليزية بأساليب حديثة ومبتكرة.'
        },
         {
          question: 'كيف يساهم منهج المعايشة في تسريع الطلاقة اللغوية؟',
          answer: 'من خلال ممارسة اللغة بشكل يومي عبر الحصص التفاعلية، يحصل الطلاب على فرص عديدة للتحدث والاستماع والتفاعل. يعمل معك فريق مكون من 4 إلى 6 معلمين شهريًا مما يعزز من سرعة تعلم اللغة. البيئة التعليمية التفاعلية التي نوفرها تساعد في تسريع اكتساب المهارات اللغوية.'
        },
       {
          question: 'كيف يمكنني التسجيل في البرنامج؟',
          answer: 'يمكنك التسجيل من خلال الموقع الإلكتروني أو عبر التواصل مع فريق خدمة العملاء عن طريق الواتساب.'
        },
         {
          question: 'ما هي مدة الحصة الدراسية وعدد الطلاب في كل مجموعة؟',
          answer: 'مدة الحصة الدراسية ساعة ونصف مرتين أسبوعيًا، وعدد الطلاب في كل حصة يتراوح بين 5 إلى 12 طالب، مما يتيح الفرصة للتفاعل المكثف.'
        },
        {
          question: 'هل سأحصل على شهادة بعد انتهاء الدورة؟',
          answer: 'نعم، عند إتمام الدورة يحصل الطالب على شهادة إلكترونية من الأكاديمية. يمكن طلب نسخة مطبوعة معتمدة إذا لزم الأمر.'
        },
         {
          question: 'هل يمكنني استرداد الرسوم؟',
          answer: 'يحق للمتدرب المطالبة باسترداد الرسوم قبل بدء الدورة بمدة لا تقل عن 48 ساعة، مع خصم رسوم اختبار تحديد المستوى. لمزيد من المعلومات، يُرجى مراجعة شروط التسجيل.'
        },
      ]
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    navigateToFAQ() {
      this.$router.push('/ar/faq');
    }
  },
};
</script>

<style src="@/assets/styles/ar/ViewThree.css" scoped></style>

